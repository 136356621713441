<template>
  <Layout>
    <div class="container mt-5 mb-5">
      <div class="row mt-5 mb-5 text-center">
        <h3>订单详情</h3>
      </div>
      <div class="row">
        <div class="course-cart py-120">
          <div class="container">
            <div class="course-cart-wrapper">
              <div class="card-body">
                <div class="mb-3 d-flex align-items-center justify-content-between">
                  <span>订单号 : <a href="#">{{ course.id }}</a></span>
                  <span
                      v-if="!isPaying"
                      style=" font-size: 18px"
                      class="btn"
                      :class="course.isPay ? 'btn-outline-success' :'btn-outline-danger'"
                  >{{
                      course.isPay ? '已支付' : '未支付'
                    }}</span>
                  <span
                      v-if="isPaying"
                      style=" font-size: 18px"
                      class="badge bg-info p-3">正在支付中</span>
                </div>
                <hr/>
                <div class="row mb-3 g-4 text-center">
                  <div class="col-md-3 col-sm-6">
                    <p class="fw-bold">课程名称</p>
                    {{ course.courseName }}
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <p class="fw-bold">课程有效期</p>
                    {{ course.courseDay }}天
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <p class="fw-bold">课程价格</p>
                    ￥{{ course.coursePrice }}.00
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <p class="fw-bold">下单时间</p>
                    {{ course.createTime }}
                  </div>
                </div>
                <hr/>
                <div class="text-center mt-5" v-if="course.isPay">
                  <router-link
                      class="link-primary"
                      :to=" { path: '/courses/details',query: { id: course.courseID} } "
                  >
                    进入课程
                  </router-link>
                </div>
              </div>
              <div class="cart-footer">
                <div class="row">
                  <div class="col-md-6 col-lg-4">
                    <div v-if="!course.isPay">
                      <div class="btn btn-danger" @click="cancel">取消并删除订单</div>
                    </div>

                  </div>
                  <div class="col-md-6 col-lg-8" v-if="!isPaying && !course.isPay">
                    <div class="cart-summary">
                      <div class="text-end mt-40">
                        <div class="btn btn-primary" @click="aliPay">支付宝付款</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  name: 'index',
  components: {Layout},
  data() {
    return {
      course: [],
      isPaying: false
    }
  },
  methods: {
    init() {
      const vm = this
      this.$axios.get('/Order/GetOrder', {params: {id: this.$route.query.id}}).then((res) => {
        vm.course = res.data.data
      })
    },
    aliPay() {
      this.$axios.post('/Pay/AliPay?orderId=' + this.$route.query.id).then((res) => {

        if (res.data.code !== 1001) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'warning'
          })
        } else {
          this.isPaying = true
          window.open(res.data.data, '_blank')
        }

      })
    },
    cancel() {
      this.$confirm('确认取消并删除订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$axios.post('/Order/CancelOrder?id=' + this.$route.query.id).then((res) => {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: res.data.code === 1001 ? 'success' : 'warning'
          })

          this.$router.push({path: '/order/list'})

        })

      })
    }

  },
  created() {
    this.init()
  }
}
</script>

<style scoped>

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin: 0px;
  font-weight: 600;
  font-family: 'Heebo', sans-serif;
  line-height: 1.2;
}

h5 {
  font-size: 18px;
}


p {
  margin: 0px;
}

.table {
  text-align: center;
}

.table > :not(:first-child) {
  border-top: none;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}

/* course cart */

.course-cart thead tr {
  background: #00AF92;
  color: #fff;
}

.course-cart thead tr th {
  text-transform: capitalize;
}

.course-cart thead tr th,
.course-cart thead tr td {
  white-space: nowrap;
}

.cart-img {
  width: 100px;
}

.cart-img img {
  width: 100%;
  border-radius: 5px;
}

.course-cart tr td {
  color: #383838;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  position: relative;
  padding: 20px;
  font-size: 16px;
}

.cart-qty {
  width: 150px;
}

.cart-qty button {
  color: #383838;
  border: 0;
  border-radius: 5px;
  padding: 4px 13px;
}

.cart-qty input {
  width: 50px;
  padding: 4px 13px;
  border-radius: 5px;
  border: none;
  background: #EFEFEF;
  text-align: center;
}

.cart-remove:hover {
  color: #da1d25;
}

.cart-footer {
  margin-top: 40px;
}

.cart-coupon {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.cart-coupon .form-control {
  box-shadow: none;
  padding: 10px 14px;
  border-radius: 5px;
}

.cart-coupon .form-control:focus {
  border-color: #00AF92;
}

.coupon-btn {
  padding: 8px 14px;
  color: #fff;
  border: none;
  background: #00AF92;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.coupon-btn:hover {
  background: #0A0F1F;
}

.cart-summary {
  float: right;
}

.cart-summary li {
  margin-bottom: 10px;
  width: 200px;
}

.cart-summary li span {
  float: right;
}

.cart-summary li strong {
  color: #030207;
}

.cart-total {
  border-top: 1px solid #eee;
}

.cart-total span {
  font-weight: bold;
  color: #da1d25;
}

.cart-summary .theme-btn {
  margin-right: 8px;
}

</style>
