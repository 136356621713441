import { render, staticRenderFns } from "./details.vue?vue&type=template&id=69bf9aaf&scoped=true"
import script from "./details.vue?vue&type=script&lang=js"
export * from "./details.vue?vue&type=script&lang=js"
import style0 from "./details.vue?vue&type=style&index=0&id=69bf9aaf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bf9aaf",
  null
  
)

export default component.exports